import React from "react"
import styled, { css } from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import { maxD } from "../../../assets/styles/helpers/devices"

import { ImageLink, PseudoUnderlinedLink } from "../common/links"
import { ClientLogo } from "../common/logos"
import {
  isLinkAnAnchor,
  notClickableButVisibleContent,
  ornament,
} from "../../../assets/styles/helpers/common"

import Arrow from "../../../assets/images/svg/right-arrow.svg"
import { Signature } from "./../../global/atoms/Signature"

const elementsPadding = css`
  padding: 2.5rem 2rem;

  @media ${maxD.mobileM} {
    padding: 2.5rem 1.5rem;
  }
`

const Testimonial = styled.figure`
  display: flex;
  flex-direction: var(--direction);

  position: relative;

  @media ${maxD.tablet} {
    flex-direction: column;
  }
`

const TestimonialLink = styled(ImageLink)`
  --position: absolute;
  --overlay-z-index: -1;

  &,
  &::after {
    width: 100%;
    height: 100%;

    left: 0;
    top: 0;
  }

  &::after {
    ${ornament}

    z-index: 3;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0) 25%,
      rgba(0, 0, 0, 0.4) 100%
    );

    transition: opacity 600ms ease, transform 1000ms var(--fancy-cubic-bezier);

    @media ${maxD.mobileM} {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0) 25%,
        rgba(0, 0, 0, 0.3) 60%,
        rgba(0, 0, 0, 0.3) 100%
      );
    }
  }

  &:not(span):hover {
    &::after {
      opacity: 0;
    }
  }
`

const TestimonialOverlay = styled(TestimonialLink)`
  &::before {
    content: none;
  }
`

const TestimonialContent = styled.div`
  ${elementsPadding}

  position: relative;
  height: 40rem;

  @media ${maxD.mobileM} {
    height: 30rem;
  }
`

const TestimonialBackground = styled(GatsbyImage)`
  z-index: -1;
`

const DescriptionContainer = styled.div`
  ${elementsPadding}

  position: absolute;
  left: 0;
  bottom: 0;

  ${notClickableButVisibleContent}

  color: #fff;
`

const Description = styled.p`
  padding-top: 0.5rem;
  font-weight: 400;
`

const SingleTestimonial = props => {
  const { link, content, testimonialname, position, logo, testimonialimage } = props.content

  return (
    <Testimonial>
      <TestimonialContent>
        {testimonialimage && (
          <TestimonialBackground
            className="full"
            image={testimonialimage.localFile.childImageSharp.gatsbyImageData}
            alt=""
          />
        )}
        {link && link.url ? (
          <>
            <TestimonialLink to={link.url} rel="nofollow" />
            {link.target === "_blank" ? (
              <PseudoUnderlinedLink
                to={link.url}
                style={{ "--link-color": "#fff" }}
                as="a"
                target="_blank"
              >
                {link.title}
                <Arrow />
              </PseudoUnderlinedLink>
            ) : (
              <PseudoUnderlinedLink
                to={link.url}
                anchor={isLinkAnAnchor(link.url)}
                style={{ "--link-color": "#fff" }}
              >
                {link.title}
                <Arrow />
              </PseudoUnderlinedLink>
            )}
          </>
        ) : (
          <TestimonialOverlay as="span" />
        )}
        <DescriptionContainer>
          {logo && (
            <ClientLogo src={logo.localFile.publicURL} alt={logo.altText} />
          )}
          <Description>{content}</Description>
        </DescriptionContainer>
      </TestimonialContent>
      <Signature name={testimonialname} position={position} />
    </Testimonial>
  )
}

export { SingleTestimonial }

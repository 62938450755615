import React from "react"
import styled from "styled-components"

import { maxD } from "../../assets/styles/helpers/devices"

import { ImageLink } from "../global/common/links"
import { ClientLogo } from "../global/common/logos"
import { Overlay } from "../global/common/ornaments"
import { isLinkAnAnchor, ornament } from "../../assets/styles/helpers/common"
import PostContent from "../global/atoms/PostContent"

import SiteLanguage from "../../helpers/siteLanguage"

const CaseStudyImageLink = styled(ImageLink)`
  &::after {
    ${ornament}

    width: 100%;
    height: 100%;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0) 25%,
      rgba(0, 0, 0, 0.4) 100%
    );
  }
`

const Logo = styled(ClientLogo)`
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 8;

  @media ${maxD.tabletL} {
    left: 1.5rem;
  }
`

const ListedCaseStudy = ({ data }) => {
  const image = {
    url: data.featuredImage?.node.localFile.childImageSharp.gatsbyImageData,
    alt: data.featuredImage?.node.altText,
  }

  const logo = {
    url: data.acfCaseStudy.whiteLogo?.localFile.publicURL,
    alt: data.acfCaseStudy.whiteLogo?.altText,
  }

  const intro = data.acfCaseStudy.intro

  const { title, uri } = data

  return (
    <PostContent
      link={uri}
      title={title}
      excerpt={intro}
      buttonLabel={ SiteLanguage() === "en" ? "Read case" : "Zobacz" }
    >
      {image.url && (
        <CaseStudyImageLink
          to={uri}
          anchor={isLinkAnAnchor(uri)}
          style={{ "--position": "static" }}
        >
          {image.url && (
            <Overlay
              image={image.url}
              alt={image.alt}
              style={{ position: "" }}
            />
          )}
          {logo.url && <Logo src={logo.url} alt={logo.alt} />}
        </CaseStudyImageLink>
      )}
    </PostContent>
  )
}

export { ListedCaseStudy }

import React from "react"
import styled, { createGlobalStyle, css } from "styled-components"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/scss"

import { minD, maxD } from "../../../assets/styles/helpers/devices"
import { quotePseudoelement } from "./../common/ornaments"

import GlobalSwiperStyles from "../../../assets/styles/components/Swiper"
import { SingleTestimonial } from "./SingleTestimonial"

SwiperCore.use([Navigation])

const CustomSwiperStyles = createGlobalStyle`
  .swiper-container-testimonials {
    overflow: visible;
  }
`

const innerSwiperStyles = css`
  .swiper-button-prev-testimonials,
  .swiper-button-next-testimonials {
    top: 10px;
  }

  .swiper-slide-next-testimonials + .swiper-slide + .swiper-slide {
    opacity: 0.5;
  }

  .swiper-button-prev-testimonials {
    left: 20px;
  }

  .swiper-button-next-testimonials {
    right: 20px;

    @media ${maxD.laptop} {
      right: -30px;
    }

    @media ${maxD.mobileL} {
      right: 20px;
    }
  }

  .swiper-button-disabled {
    display: none;
  }
`

const Container = styled(Swiper)`
  ${innerSwiperStyles}

  padding: 40px 0 var(--section-spacing);

  @media ${maxD.mobileL} {
    padding: 50px 0;
  }

  &::before,
  &::after {
    ${quotePseudoelement}
  }

  &::before {
    left: -25px;

    @media ${maxD.tabletL} {
      top: 25%;
    }
  }

  &::after {
    left: calc(66.66% - 25px);

    @media ${maxD.laptop} {
      left: calc(50% - 25px);
      top: 60%;
    }

    @media ${maxD.tabletL} {
      top: 30%;
    }
  }
`

const Button = styled.button`
  @media ${minD.laptop} {
    ${({ visibleOnDesktop }) =>
      visibleOnDesktop ||
      css`
        display: none;
      `}
  }
`

const TestimonialsInner = ({ testimonials }) => (
  <>
    <GlobalSwiperStyles />
    <CustomSwiperStyles />
    <Container
      loop={false}
      slidesPerView={1}
      breakpoints={{
        767: {
          slidesPerView: 2,
        },
        1279: {
          slidesPerView: 3,
        },
      }}
      navigation={{
        nextEl: ".swiper-button-next-testimonials",
        prevEl: ".swiper-button-prev-testimonials",
      }}
      className="swiper-container-testimonials"
    >
      {testimonials &&
        testimonials.map((testimonial, i) => {
          const isReversed =
            i % 2 === 0
              ? { "--direction": "column" }
              : { "--direction": "column-reverse" }

          return (
            <SwiperSlide style={isReversed} key={`${testimonial.id}-${i}`}>
              <SingleTestimonial content={testimonial.acfSingleTestimonials} />
            </SwiperSlide>
          )
        })}
      <Button
        className="swiper-button-prev swiper-button-prev-testimonials"
        aria-label="Previous slide"
        visibleOnDesktop={testimonials && testimonials.length > 3}
      />
      <Button
        className="swiper-button-next swiper-button-next-testimonials"
        aria-label="Next slide"
        visibleOnDesktop={testimonials && testimonials.length > 3}
      />
    </Container>
  </>
)

export { TestimonialsInner }
